
// @ts-nocheck
import Vue from "vue";

import CompanyView from "@/components/CompanyView.vue";

export default Vue.extend({
  name: "Company",
  components: {
    CompanyView,
  },
  data() {
    return {
      url: null,
    };
  },
  computed: {
    domain() {
      if (!this.url) return null;
      const urlParts = new URL(this.url);
      const { protocol, hostname } = urlParts;
      // don't parse `chrome://` urls as domains etc
      if (protocol.startsWith("http")) return hostname;
      else return null;
    },
  },
  created() {
    this.getPageUrl();
  },
  methods: {
    getPageUrl() {
      chrome?.tabs?.query({ active: true, currentWindow: true }, (tabs) => {
        this.url = tabs[0].url;
      });
    },
  },
});
